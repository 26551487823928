.table {
  width: 100%;
}

.table-collapsed {
  width: calc(100% - 24px);
  padding-right: 24px;
}

.head {
  color: #272D31;
  font-weight: 600;
  font-size: 14px;
  height: 36px;
}

.td-left {
  min-width: 90px;
}

.head-center {
  min-width: 100px;
}

.head-border {
  border-bottom: 1px solid #DA2830;
}

.tr {
  height: 42px;
}

.clickable-td {
  outline: none;
  cursor: pointer;
}

.td {
  color: #272D31;
  font-size: 14px;
  border-bottom: 1px solid #EEEEEE;
}

.td-left-padding {
  padding-left: 14px;
}

.td-left-padding-standard {
  padding-left: 44px;
}

.td-outline {
  outline: none;
  cursor: pointer;
}

.td-standard {
  font-weight: 400;
}

.td-center {
  font-weight: 500;
}

.td-head-right {
  text-align: right;
  padding-right: 0px;
}

.td-head-right-standard {
  text-align: right;
  padding-right: 0px;
}

.td-right {
  text-align: right;
  padding-right: 20px;
}

.td-right-standard {
  text-align: right;
  padding-right: 20px;
}

.td-right-collapsed {
  text-align: right;
}

.td-head-right-collapsed {
  text-align: right;
  padding-right: 0px;
}

.hover-button .hover-button--on,
.hover-button:hover .hover-button--off {
  display: none;
}

.hover-button:hover .hover-button--on {
  display: inline;
}

@media screen and (max-width: 768px) {
  .table {
    padding-left: 10px;
    padding-right: 10px;
  }

  .table-collapsed {
    width: calc(100% - 14px);
    padding-right: 14px;
  }

  .head {
    font-size: 12px;
  }

  .td-right {
    text-align: left;
  }

  .td-head-right {
    text-align: left;
    padding-right: 10px;
  }

  .td-head-right-collapsed {
    text-align: right;
    padding-right: 10px;
  }

  .td-left-padding {
    padding-left: 0px;
  }

  .td-left-padding-standard {
    padding-left: 0px;
  }

  .td {
    font-size: 11px;
  }

  .td-center {
    max-width: 110px;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
  }

  .td-left {
    max-width: 115px;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
  }
}