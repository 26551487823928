.container {
  padding-top: 60px;
  height: calc(100% - 60px);
  width: 100%;
  background-color: #F7F7F9;
}

fieldset {
  border: 0;
  padding: 0;
}

.container-with-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.content-select {
  padding-top: 3px;
  padding-bottom: 3px;
  width: 145px;
}

.subtitle {
  font-size: 18px;
  color: #414141;
  letter-spacing: 0;
  font-weight: 500;
}

.left-dashboard-content {
  position: absolute;
  height: calc(100vh - 100px);
  width: calc(100vw - 434px);
  padding: 20px 4px 20px 44px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #F7F7F9;
}

.right-dashboard-content {
  height: calc(100vh - 100px);
  background-color: #FFFFFF;
  width: 345px;
  float: right;
  padding: 20px 4px 20px 20px;
  border-left: 1px solid #BEBEBE;
  overflow: auto;
}

.left-menu-content {
  position: absolute;
  height: calc(100vh - 80px);
  width: 292px;
  padding: 0px 0px 20px 0px;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #BEBEBE;
  background-color: #FFFFFF;
}

.left-closed-collection-content {
  position: absolute;
  height: calc(100vh - 80px);
  width: 283px;
  padding: 0px 0px 20px 10px;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #BEBEBE;
  background-color: #FFFFFF;
}

.right-standard-content {
  height: calc(100vh - 70px);
  width: calc(100vw - 309px);
  float: right;
  padding: 0px 4px 10px 12px;
  border-left: 1px solid #BEBEBE;
  overflow: auto;
}

.grid-content {
  text-align: left;
  background-color: #FFFFFF;
  border: 1px solid #DFDFDF;
  height: 194px;
  width: auto;
  min-width: 174px;
  border-radius: 5px;
  margin-top: 12px;
  cursor: pointer;
}

.enabled-section {
  top: 0px;
  margin: 0px 0px 0px -3px;
  border-width: 3px;
  border-bottom: 2px white solid;
  width: auto;
  height: 58px;
  border-color: #DA262E;
}

.disabled-section {
  width: auto;
  height: 58px;
}

.menu-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  width: auto;
  padding-left: 24px;
  padding-right: 24px;
  height: 58px;
  outline: none;
}

.add-button-container {
  padding-top: 18px;
  height: 32px;
  width: 100%;
}


.disabled-label-menu {
  font-size: 13px;
  color:  #414141;
  font-weight: 500;
}

.enabled-label-menu {
  font-size: 13px;
  color:  #DA262E;
  font-weight: 700;
}

.item-content {
  margin-top: 12px;
  width: 325px;
}

.item-content div {
  float:right;
}

.item-content-left {
  width: 50px;
  height: 62px;
  border-bottom:1px solid #D3D3D3;
}

.item-content-center {
  width: 163px;
  height: 50px;
  padding-top: 12px;
  border-right: 1px solid #D3D3D3;
  border-bottom:1px solid #D3D3D3;
}

.item-content-right{
  width: 110px;
  height: 31px;
  text-align: right;
  padding-top: 31px;
  border-bottom:1px solid #D3D3D3;
}

.closed-number {
  float: right;
  color: #DA262E;
  font-weight: 800;
  padding-right: 22px;
  padding-top: 16px;
  font-size: 18px;
}

.device-number {
  float: right;
  color: #DA262E;
  font-weight: 800;
  padding-right: 14px;
  padding-top: 30px;
  padding-bottom: 12px;
  font-size: 18px;
}

.clicked {
  cursor: pointer;
  outline: none;
}

.content-table-collapsed {
  padding-top: 24px;
  width: 100%;
}

.search-content {
  padding: 16px 16px 12px 16px;
}

.title-font-size {
  font-size: 18px;
}

.subtitle-font-size {
  font-size: 13px;
}

.label-radio-font-size {
  font-size: 14px;
}

.radio-content-margin {
  margin-left: 26px;
}

.grid-header-margin {
  margin-top: 20px;
  margin-left: 25.5%;
}

.subtitle-content {
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
  width: 100%;
  max-width: 184px;
  margin-top: 40px;
  padding-right: 10px;
}

.grid-content-margin {
  margin-top: -20px;
  margin-left: 25.5%;
}

.grid-hr {
  width: 74.5%;
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #E02020;
}

.img-content {
  height: 160px;
  padding-top: 6px;
}

.collection-date {
  margin-top: 2px;
  margin-left: 0px;
  font-size: 15px;
  font-weight: 400px;
  color: #001124;
}

.block {
  /* margin-top: 0px; */
  margin-left: 8px;
  margin-right: 8px;
  width: calc(100% - 16px);
}

.pagination-container {
  padding: 0px;
  width: 100%;
}

.pagination-page-font-size {
  font-size: 15px;
}

.pagination-page-size-font-size {
  font-size: 14px;
}

@media screen and (max-width: 1279px) {
  .grid-header-margin {
    margin-top: 10px;
    margin-left: 0%;
  }

  .subtitle-content {
    width: calc(100% - 10px);
    max-width: calc(100% - 10px);
  }

  .grid-content-margin {
    margin-top: 0px;
    margin-left: 0%;
  }

  .grid-hr {
    width: 100%;
  }

  .img-content {
    height: 150px;
  }

  .block {
    margin-top: 20px;
  }

  .pagination-container {
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% - 20px);
  }
  
}

@media screen and (max-width: 768px) {
  .left-dashboard-content {
    position: absolute;
    z-index: 999;
    height: 270px;
    width: calc(100vw - 20px);
    padding: 20px 10px 4px 10px;
    overflow: auto;
  }

  .right-dashboard-content {
    height: calc(100vh - 363px);
    width: calc(100vw - 20px);
    bottom: 0px;
    margin-top: 278px;
    padding: 20px 10px 4px 10px;
    overflow: auto;
  }

  .left-menu-content {
    position: absolute;
    z-index: 999;
    height: 160px;
    width: calc(100vw - 20px);
    padding: 20px 10px 4px 10px;
    overflow: auto;
  }

  .left-closed-collection-content {
    position: absolute;
    z-index: 999;
    height: 172px;
    width: calc(100vw - 20px);
    padding: 6px 10px 4px 10px;
    overflow: auto;
  }

  .right-standard-content {
    height: calc(100vh - 244px);
    width: 100vw;
    bottom: 0px;
    margin-top: 182px;
    padding: 0px 0px 10px 0px;
    overflow: auto;
  }

  .item-content {
    width: calc(100vw - 28px);
  }

  .item-content-center {
    width: calc(100vw - 190px);
  }

  .container-with-padding {
    padding-left: 10px;
    padding-right: 10px;
  }

  .content-select {
    width: 130px;
  }

  .closed-number {
    padding-top: 18px;
    font-size: 12px;
    padding-right: 10px;
  }

  .device-number {
    padding-top: 18px;
    font-size: 12px;
    padding-right: 10px;
  }

  .content-table-collapsed {
    padding-top: 24px;
    width: calc(100% - 20px);
  }

  .add-button-container {
    width: 100vw;
    float: right;
    padding: 18px 10px 0px 10px;
  }

  .search-content {
    padding: 0px 4px 16px 4px;
  }

  .title-font-size {
    font-size: 14px;
  }

  .subtitle-font-size {
    font-size: 11px;
  }

  .label-radio-font-size {
    font-size: 12px;
  }

  .radio-content-margin {
    margin-left: 0px;
  }

}

@media screen and (max-width: 640px) {
  .menu-button {
    text-align: left;
    outline: none;
  }

  .pagination-page-font-size {
    font-size: 11px;
  }

  .pagination-page-size-font-size {
    font-size: 11px;
  }

}
