a {
  color: #4A4A4A;
  height: 40px;
}

.header {
  background-color: #FFFFFF;
  position: fixed;
  width: 100%;
  z-index: 1001;
  border-bottom: 1px solid #B8B7B7;
}

.header-add-user {
  padding-top: 18px;
  height: 42px;
  width: 100%;
  position: fixed;
  border-bottom: 1px solid #B8B7B7;
  background-color: #FFFFFF;
}

.header-closed-collection {
  padding-top: 8px;
  height: 50px;
  width: 100%;
  position: fixed;
  border-bottom: 1px solid #B8B7B7;
  background-color: #FFFFFF;
}

.left-header-content {
  float: left;
  height: 100%;
  width: 44px;
  padding-left: 20px;
  outline: none;
  cursor: pointer;
}

.right-header-content {
  float: right;
  height: 100%;
  width: calc(100vw - 76px);
  padding-left: 12px;
}

.button-add-user {
  font-size: 11px;
  width: 60px;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #FFFFFF;
}

.header .menu-language {
  position: fixed;
  margin-left: 200px;
  margin-top: 7px;
}

.header li a {
  display: block;
  padding: 0px 28px 20px;
  font-size: 14px;
  font-weight: 500px;
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: #f4f4f4;
}

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

.header .menu-icon {
  cursor: pointer;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 480px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

.icon-right-container {
  width: 58px;
  height: 58px;
  float: left;
  margin-left: 52px;
}

.container-tooltip {
  position: relative;
}

.img-tooltip + .tooltiptext {
    visibility: hidden;
    width: 330%;
    background-color: #748591;
    color: #FFFFFF;
    text-align: center;
    border-radius: 3px;
    padding: 2px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-top: -2px;
    margin-left: -76px;
    font-size: 10px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 1s;
}

.img-tooltip + .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 71%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color:transparent transparent #748591 transparent;
}

.img-tooltip:hover + .tooltiptext {
  visibility: none;
  opacity: 1;
}

@media (min-width: 1025px) {
  .header li {
    float: left;
  }
  .header li a {
    padding: 0px 1px 20px 3px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
  .icon-right-container {
    width: 58px;
    height: 58px;
    float: right;
  }

  .button-add-user {
    font-size: 14px;
    width: 94px;
  }

  .left-header-content {
    width: 274px;
  }

  .right-header-content {
    width: calc(100vw - 306px);
  }

  .img-tooltip:hover + .tooltiptext {
    visibility: visible;
    opacity: 1;
}
}